import type { JSX } from "solid-js";
import { children, createEffect, onMount } from "solid-js";
import type { InputTypeTextProps } from "~/components/Forms/Fields/Base/InputTypesProps";

import { createSignal, createUniqueId, Show } from "solid-js";
import { useFormContext } from "~/contexts/FormContext";

export function InputTypeText(props: InputTypeTextProps) {
  const resolvedAppendIcon = children(() => props.appendIcon);
  const [, { setValue: formSetValue }] = useFormContext();
  const setValue = props.defaultFormStorage
    ? props.defaultFormStorage
    : formSetValue;

  const isRequired = () => props.required || false;
  const size = () => props.size || 60;
  const maxlength = () => props.maxLength || 128;
  // eslint-disable-next-line solid/reactivity
  const [inputHasContent, setInputHasContent] = createSignal(!!props.value);
  const [inputIsFocus, setInputIsFocus] = createSignal(false);
  const [touched, setTouched] = createSignal(false);
  const [blurCount, setBlurCount] = createSignal(0);

  const setHasContent: JSX.EventHandler<HTMLInputElement, Event> = (e) => {
    setInputHasContent(e.currentTarget.value.length > 0);
  };

  // eslint-disable-next-line solid/reactivity
  const id = `${props.name}-${createUniqueId()}`;

  onMount(() => {
    props.validateFn && props.value && props.validateFn(props.value);
    setInputHasContent(!!props.value);
  });

  createEffect(() => {
    setInputHasContent(!!props.value);
  });

  return (
    <>
      <div
        class={`form-control form-control-${props.name}`}
        classList={{
          "is-focus": inputIsFocus(),
          "has-content": inputHasContent(),
          "in-error": props["aria-invalid"],
          "is-disabled": props.disabled,
        }}
      >
        <div
          class={`form-slot form-text-field form-type-${props.type ?? "text"}`}
          classList={{ "with-prepend-icon": !!props.prependIcon }}
        >
          <Show when={resolvedAppendIcon()} keyed>
            <i aria-hidden="true" class="cog-icon prepend-icon">
              {resolvedAppendIcon()}
            </i>
          </Show>

          <Show when={props.label} keyed>
            <label for={id}>
              {props.label}
              <Show when={isRequired()} keyed>
                {" "}
                *
              </Show>
            </label>
          </Show>

          <input
            type={props.type ?? "text"}
            id={id}
            name={props.name}
            data-test={props.name}
            size={size()}
            maxlength={maxlength()}
            required={isRequired()}
            value={props.value || ""}
            placeholder={props.placeholder}
            aria-required={isRequired()}
            aria-invalid={props["aria-invalid"] || false}
            aria-errormessage={
              props["aria-invalid"] ? props["aria-errormessage"] : undefined
            }
            onFocusIn={() => setInputIsFocus(true)}
            onFocusOut={() => setInputIsFocus(false)}
            onChange={(event) => setHasContent(event)}
            onFocus={(event) => {
              !touched() && setTouched(true);
              props.onFocus && props.onFocus(event);
            }}
            onInput={(event) => {
              if (props.onInput) {
                props.onInput(event);
              }
              if (props.validateFn && touched() && blurCount() > 0) {
                props.validateFn(event.currentTarget.value);
              }
              setValue!(props.name, event.currentTarget.value);
            }}
            onBlur={(event) => {
              setBlurCount((prev) => prev + 1);
              if (props.onBlur) {
                props.onBlur(event);
              }
              if (props.validateFn && touched()) {
                props.validateFn(event.currentTarget.value);
              }
            }}
          />
          <Show when={"appendIcon" in props} keyed>
            <i aria-hidden="true" class="cog-icon">
              {props.appendIcon}
            </i>
          </Show>
          <Show when={"appendText" in props} keyed>
            <i class="append-text">{props.appendText}</i>
          </Show>
        </div>
        <Show when={props.help} keyed>
          <div class="field-help">{props.help}</div>
        </Show>
      </div>
    </>
  );
}
